html {
    scroll-behavior: smooth;
}

#drones-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#drones-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    display: flex;
    padding: 1em;
    justify-content: center;
    align-items: center;
    border: 1px solid #0c0d2a;
    color: black;
    margin: 2em;
    text-decoration: none;
    transition: background-color ease-out 1s;
}

    .btn:hover {
        cursor: pointer;
        background-color: #b0a6a6;     
    }

    .btn:visited {
        color: black;
    }

#drones-story {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em;
    font-size: 1.2em;
    white-space: pre-line;
    width: 70%;
}

.drone {
    background-color: #b0a6a6;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    margin-top: 1em;
    margin-bottom: 1.5em;
    width: 100%;
}

.drone > * {
    margin-inline: 2em;
}

.drone-intro {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drone-info {
    width: 60%;
    margin-bottom: 1em;
}

.drone-info > p {
    white-space: pre-line;
}

.drone-photo {
    margin-bottom: 1em;
}

.drone-data {
    text-decoration: none;
    background-color: #4F5D75;
    padding: 1em;
    color: #d9d9d9;
}

    .drone-data:hover {
        cursor: pointer;
        background-color: rgb(79, 93, 117, .7);
    }

.drone-table {
    border: 2px solid;
    border-collapse: collapse;
    margin: 2em;
    border-radius: 40px;
}

.table-title {
    background-color: #686666;
    border: 1px solid;
    width: 100%;
    font-size: 1.1em;
}

.table-title > th {    
    padding-left: .5em;
    font-weight: 500;
}

.table-header {
    border: 1px solid;    
    padding-inline: .5em;
}

.table-cell {
    border: 1px solid;
    padding-inline: .5em;
    overflow-wrap: break-word;
    max-width: 40vw;
}

#gallery-photos {
    display: flex;
}

.drone-image {
    width: 300px;
    height: 200px;
    margin: .5em;
    border: .5em solid #514b4b;
}

    .drone-image:hover {
        cursor: pointer;
        opacity: .8;
    }

#gallery-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(45, 49, 66, .8);
}

#main {
    display: flex;
    margin-bottom: 1em;      
}

#main > img {
    width: 600px;
    height: 400px;
    border: none;
    opacity: 1;
}

#small {
    background-color: rgb(45, 49, 66);
}

.gallery-small {
    width: 150px;
    height: 100px;
    margin: .5em;
    opacity: 1;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  /* -webkit-user-select: none; */
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev {
    left: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.video {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #514b4b;
    padding: .5em;
    margin-top: 2.5em;
    margin-left: 2em;
    width: 800px;
    height: 450px;
}

.video-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
}

.play-pause {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(0, 0, 0, .7);
    border-radius: 4px;
    background-color: rgb(0, 0, 0, .7);
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 2;
}

.fade {
    opacity: 0;
    transition: opacity ease-out .6s;
}

    .fade:hover {
        opacity: 1;
    }

.video-title {
    padding: .4em;
}

    .video-title:hover {
        cursor: pointer;
    }

.drone-results {
    margin-left: 2em;
}

.drone-results > h3 {
    color: #4F5D75;
    font-size: 1.4em
}

#results > img {
    width: 400px;
    height: 300px;
    margin-inline: .5em;
}

@media (max-width: 970px) {
    
    .drone-intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .table-header {
        overflow-wrap: break-word;
        max-width: 30vw;
    }

    .drone-cell {
        width: initial;
    }
}

@media (max-width: 830px) {
    #drones-navigation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .drone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .drone > * {
        margin-inline: 0;
    }

    .drone-name {
        justify-content: center;
    }

    .drone-intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .drone-intro > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }    

    .video {
        width: 100vw;
    }
}

@media (max-width: 400px) {

    h2 {
        font-size: 1.1em;
    }

    p {
        font-size: .7em;
        overflow-wrap: break-word;
        width: 70%;
    }

    ul {
        width: 70%;
        font-size: .7em;
    }

    h4 {
        text-align: center;
    }

    
    .btn {
        font-size: .8em;
        margin: .6em;
    }

    .drone {
        margin-top: 1em;
    }

    .drone-photo {
        width: 40%;
        height: 30%;
    }

    .drone-data {
        padding: .6em;
        font-size: .8em;
    }

    .drone-table > * {
        font-size: .7em;
    }

    #gallery-photos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .drone-image {
       width: 40vw;
       height: 30vw; 
    }

    #main > img {
        width: 80vw;
        height: 60vw;
    }

    .gallery-small {
        width: 30vw;
        height: 20vw;
        min-width: 80px;
        min-height: 60px;
    }

    .video {
        margin-left: 0;
    }

}