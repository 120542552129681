#error-page, #error-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#error-content {
    margin-top: 10vh;
}

#error-status {
    display: flex;
    margin: 1em;
    padding: 1.5em 3em;
    background-color: #f53535;
    width: 10%;
    align-items: center;
    justify-content: center;
}