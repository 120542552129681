#mainContent {
    /* height: 200vh; */
    width: 100vw;
}

#welcome-ad {
    height: 80vh;
    width: 100vw;
    background-color: black;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

#welcome-ad>h3,
h1 {
    margin-right: 10vw;
}

#welcome-btns {
    display: flex;
    margin-right: 12vw;
}

#welcome-btns>div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-inline: 5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    font-size: 100%;
    font-weight: 600;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: transparent;
}

#welcome-btns>div>img {
    height: 20px;
    width: 20px;
    padding-left: 5px;
    display: none;
}

#welcome-btns>div:hover {
    cursor: pointer;
    color: #edbb5f;
}

#welcome-btns>div:hover img {
    display: block;
}

#welcome-btn1 {
    width: 102px;
    transition: width .3s;
}

#welcome-btn1:hover {
    width: 127px;
}

#welcome-btn2 {
    width: 144px;
    transition: width 0.2s;
}

#welcome-btn2:hover {
    width: 170px;
}

#welcome-intro {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    /* background-color: black; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 3em 0;
    color: #fff;
    background-image: url("../assets/images/who-we-are.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#intro-bkgd {
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    object-fit: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#introduction-text {
    padding: 2em 1em;
    width: 60%;
    position: absolute;
    z-index: 50;
    white-space: pre-wrap;
}

#introduction-text>div {
    padding: 0.6em;
}

#introduction-text>div:hover {
    background-color: #000000a0;
}

@media (max-width=428px) {
    /*  */
}