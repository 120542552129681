select {
    margin-left: 15px;
}

#header {
    width: 100%;
    background-color: #d9d9d9;
    display: flex;
    flex-direction: column;
}

#header-background {
    object-fit: cover;
    height: 20vh;
    width: 100%;
    min-height: 8vw;
}

#site-logo:hover {
    cursor: pointer;
    opacity: .8;
}

#site-logo-img {
    z-index: 5;
    position: absolute;
    width: 4vw;
    height: 6vw;
    min-width: 40px;
    min-height: 70px;
    top: 3vh;
    left: 18vw;
}

#navigation-bar {
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d9d9d9;
}

.nav-btn {
    margin-left: 15px;
    margin-right: 15px;
    border: none;
    background-color: transparent;
    font-size: 100%;
    font-weight: 550;
    text-decoration: none;
    color: #35160d;
}

    .nav-btn:hover {
        cursor: pointer;
        transition: all .4s ease-in-out;
        opacity: .7;
    }

.nav-btn.btn-disabled {
    color: #666;
}

    .nav-btn.btn-disabled:hover {
        cursor:default;
        opacity: 1;
    }

@media (max-width: 1080px) {
    #site-logo-img {
        z-index: 5;
        position: absolute;
        width: 8vw;
        height: 14vw;
        min-width: 40px;
        min-height: 70px;
        top: 4vh;
        left: 16vw;
    }
}

@media (max-width: 470px) {

    select {
        font-size: 70%;
    }

    .nav-btn {
        margin-left: 5px;
        margin-right: 5px;
        border: none;
        background-color: transparent;
        font-size: 70%;
        font-weight: 550;
    }
    
    #site-logo-img {
        width: 12vw;
        height: 16vw;
        top: 6vh;
        left: 15vw;
    }
}
