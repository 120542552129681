#valves-page {
    width: calc(100% - 10vw);
    margin: 0 5vw;
}

h1 {
    font-size: 4em;
}

.bold {
    font-weight: bold;
}

#valves-title-hero {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-height: 50vh;
    padding: 2em 5vw;
    /* background-image: url("../assets/images/F-Reg_VHA_001.png");
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover; */
}

#valves-title-hero #valves-title-hero-text {
    display: flex;
    flex-direction: column;
    width: 50%;
}

#valves-title-hero #valves-title-hero-text>div {
    display: grid;
    grid-template-columns: fit-content(50%) fit-content(50%);
    column-gap: 1em;
    row-gap: 1em;
}

#valves-title-hero img {
    display: flex;
    width: 40%;
    max-height: 70vh;
    /* object-fit: cover; */
    /* object-position: bottom -5em left 0; */
}

#valves-title-hero h1 {
    /* position: absolute; */
    /* right: 3em; */
    /* bottom: 30vh; */
    /* color: #d9d9d9; */
    text-shadow: 0px 0px 1px #000;
}

#valves-title-hero #valves-title-hero-text #valves-company-introduction {
    display: flex;
    width: 80%;
    margin: 1em 0;
}

#valves-title-hero .valves-company-quality {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
    font-size: 1.1em;
    background-color: #d9d9d9;
    padding: 1em;
    box-shadow: 0px 0px 4px #0000006f;
    border-radius: 8px;
}

#valves-story {
    white-space: pre-line;
    padding: 2em 5vw;
}

#valves-list ul {
    text-decoration: none;
    list-style-type: none;
}

#valves-list li {
    border-radius: 10px;
    background-color: #d9d9d9;
    box-shadow: 1px 1px 5px #0000008c;
    padding: 1em 2em;
    margin: 1em;
}

#explorer-container {
    display: flex;
    width: calc(100% - 10vw);
    padding: 2em 5vw;
    flex-direction: column;
}

#explorer-container-content {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#explorer-container-folders {
    flex: 1;
}

#explorer-container-presenter {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #514B4B;
    padding-bottom: 1.5em;
}

#explorer-container-presenter img {
    width: 80%;
    max-height: 70vh;
    margin-top: 2em;
}

.explorer-folder {
    padding: 1.5em 1em;
}

.explorer-folder-title {
    font-weight: bold;
    font-size: 1.2em;
}

.explorer-folder-file {
    font-size: 1em;
    padding: .5em;
    border-radius: 5px;
}

.explorer-folder-file:hover {
    cursor: pointer;
    background-color: #4F5D75;
    color: #fff;
}

.presented-file {
    background-color: #4F5D756c;
}

#explorer-container-attachment {
    text-decoration: none;
    background-color: #4F5D75;
    padding: 1em;
    color: #d9d9d9;
    border: none;
}

#explorer-container-attachment:hover {
    opacity: .8;
}

#valves-explanation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em 5vw;
}

#valves-explanation img {
    width: 80%;
    padding: 1em;
}